import React from 'react'
import logo from "../Assets/logo.png"

import { CiInstagram, CiFacebook, CiTwitter, CiLinkedin, CiMenuBurger } from "react-icons/ci";
import { Link, useNavigate } from 'react-router-dom';


const Footer = () => {

  const navigate = useNavigate();

  return (
    <>
      <div id="container" className='mt-4 p-3 md:flex bg-[#edf6ff]'>
        <div id="logo" className='grid place-items-center mb-5 md:w-[20%]'>
          <img className='w-auto h-[3rem] md:h-[4rem]' src={logo} alt="" />
          <div id="socials" className='flex justify-center gap-4'>
            <Link to={"https://www.instagram.com/creativehighschoolthane?igsh=cHE2aDJkMXpqYmlu"} target="_blank">
              <CiInstagram size={20} className='hover:text-[#0f3661] cursor-pointer' />
            </Link>
            <Link to={"https://www.facebook.com/profile.php?id=61556264173968&mibextid=ZbWKwL"} target='_blank'>
              <CiFacebook size={20} className='hover:text-[#0f3661] cursor-pointer' />
            </Link>
            <Link to={"https://x.com/Creative061998?t=n5g_D1Po5QpW1BpoWP5n2w&s=09"} target="_blank">
              <CiTwitter size={20} className='hover:text-[#0f3661] cursor-pointer' />
            </Link>
          </div>
        </div>
        <hr />
        <div id="content">
          {/* addr contact pages */}
          <div className='grid grid-cols-3 my-2 md:flex gap-3 md:text-lg font-semibold'>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/")}}>Our History</div>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/ourngo")}}>Our NGO</div>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/aboutus")}}>About Us</div>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/meritboard")}}>Merit Board</div>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/commitment")}}>Our Commitments</div>
            <div className='p-1 px-2 hover:border-y-2 border-black transition ease-in-out duration-300' onClick={() => {navigate("/contactus")}}>Contact Us & Location</div>
          </div>
          <hr />
          <div id="addr-and-contact" className='mt-6 font-semibold'>
            <div id="addr" className='text-xs md:text-lg'>
              <p>7, Shivraj Nagar, Almeida Road, Panchpakahdi, Thane 400602</p>
            </div>
            <div id="contact" className='mt-4 text-xs flex gap-5'>
              <p>creativeschool@rediffmail.com</p> <span>|</span> <p>9987726666 / 9967635555</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer