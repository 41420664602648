import { motion } from 'framer-motion'
import { Table } from 'rsuite';
import React from 'react'

import vision from "../Assets/ourvision.svg"
import mission from "../Assets/mission.svg"

import pg21 from "../Assets/pg21.jpeg"
import pg22 from "../Assets/pg22.jpeg"
import pg23 from "../Assets/pg23.jpeg"
import pg24 from "../Assets/pg24.jpeg"
import pg25 from "../Assets/pg25.jpeg"

const Ourngo = () => {
  return (
    <>
        <div id="container" className='font-bold'>
            <motion.h2 className='text-center my-4'
                initial={{
                    x: -100
                }}
                
                whileInView={{
                    x: 0,
                    transition: "ease-in-out",
                    transitionDuration: 2,
                    transitionDelay: 1
                }}
            >
                Our NGO
            </motion.h2>
            <div id="textContent" className='grid place-items-center'>
                <motion.p className='md:text-center mx-2 md:w-[60%]'
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    Established IN 1996 OPK Educational & Charitable Trust a Non profit, Non governmental, Humanitarian organization dedicated to well defined social objective of school centered community development. The trust is registered with the Charity Commissioner under Bombay Act XXXIX of 1950, with Department of Income Tax under 12A and 80 G and with ministry of Home Affairs, ( Govt. Of india ) under FCRA Act of 2010, <br/> The Trust has CSR1 certificate from Ministry of Corporate Affairs and registration with NGO Darpan of NITI AYOG with Unique ID <br/> Mrs. Kamla Kanaujia is the founder and settler of the Trust. The trust is working on No Profit No Loss Basis.
                </motion.p>

                <div className='font-semibold my-5 text-center mx-4'>
                    OPK EDUCATIONA AND CHARITABLE TRUST BASIC (EST. 1996)
                </div>

                <div className='font-semibold my-5 text-center mx-3'>
                    BASIC INFORMATION OF THE ORGANISATION.
                </div>

                <div className='overflow-x-scroll'>
                    <table className='border'>
                        <tbody className='border'>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>01</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Name of NGO</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>OPK Educational and Charitable Trust</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>02</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Address of the Head office</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>7, Shivraj Nagar, Almeida Road, Panchpakahdi,Thane 400602</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>03</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Legal Status</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Registered under Bombay Public Trust Act. 1950 With Registered No. E1680</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>04</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Works since the year</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>31.10.1996</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>05</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Email Address</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>creativeschool@rediffmail.com</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>06</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Website</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>www.creativehighschool.in</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>07</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Organization Contact No.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>9987726666 / 9967635555</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>08</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Name of the Contact Person</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Mrs. Kamla Omprakash Kanaujia</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>09</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Designation of Contact Person Founder</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Retd. Principal, Secretary of the Trust, Administrator of Creative High School.</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>10</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Cell phone of the Contact person</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>9967635555/9987726666</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>11</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Geographical area of work</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>In Slum Dominated areas of Thane City.</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>12</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Area of work</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Education center community Development</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>13</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>Income Tax Exemption</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>80G and 12A</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>14</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>PAN NO.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>15</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>TAN NO.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>16</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>NGO Darpan Unique ID</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>MH / 2018 / 0210104</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>17</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>CSR Registration No.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>CSR 00027654</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>18</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>FCRA Registration No.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>19</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>School Primary & Secondary Registeration no.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>20</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>School Primary & Secondary UDISE No.</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>
                            <tr >
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>21</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>SSC index No. from Mumbai Divisonal Board</td>
                                <td style={{border: "1px solid black", paddingLeft: "5px", paddingRight: "5px"}}>YES</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <h3 className='mt-4'>Our Vision</h3>
                <motion.div id="vision" className='md:flex justify-center font-semibold my-10'
                    initial={{
                        x: 100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="flexcard">
                        <img className='md:w-[80%]' src={vision} alt="" />
                    </div>
                    <div className='grid '>
                        <h3>EVERY CHILD DESERVES THE BEST</h3> <br/>
                        <div id="content" className='text-lg italic'>
                        "No good deed is ever wasted <br/>
    No kind words said in vain <br/>
    For the good we do for others <br/>
    Life brings back to us again"     
                        </div>
                    </div>
                </motion.div>

                <h3 className='mt-4'>Our Mission</h3>
                <motion.div id="vision" className='md:flex justify-center font-semibold my-10'
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="content" className='text-lg  mx-3 italic'>
                    <h3 className='my-2 font-extrabold'>
                        "NOT ONLY WHAT TO LEARN BUT HOW TO LEARN TOO"    
                    </h3> <br/> <br/>
Every seed of love sowed <br/>
Will spring somewhere on the road <br/>
And the sacrifices we make <br/>
Will serve to Lighten someone's load

                    </div>
                    <div id="flexcard">
                        <img className='md:w-[80%]' src={mission} alt="" />
                    </div>
                </motion.div>

                <h3 className='mt-4 underline'>Our Motto</h3>
                <motion.div id="vision" className='md:flex justify-center font-semibold my-10'
                    initial={{
                        x: 100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="content" className='text-lg mx-3 italic'>
                        Excellence @ tenacity + Resilience <br/>
                        BUILDING FOUNDATION OF FUTURES.
                    </div>
                </motion.div>
                
                <h3 className='mt-4 underline'>Present Scenario</h3>
                <motion.div id="vision" className='md:flex justify-center font-semibold my-10'
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="content" className='mx-2 md:w-[50%]'>
                    Creative School is an Association of Nursery, Pre-Primary, Primary and Secondary
Schools at Thane. The School was started with intention of helping children coming from
humble economic background. <br/>
 Creative School is a Volunteer venture of a group of educationists and Teachers of Thane. Founded in 1998 Creative School is 25 years old institution.
                    </div>
                </motion.div>

                <motion.div id="vision" className='md:flex justify-center font-semibold my-10'
                    initial={{
                        x: 100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="content" className='mx-2 md:w-[50%]'>
                    Working under untimited limitations presently as all the sections are working in rental
Premises. Though limitations have not limited the forward marching towards
achievements. In Short it can be summed up as example of maximum performance in
minimum resources.
<br/>
No Child has ever failed SSC board in Std. 10" Exam till date when thirteen Batches have
 already passed out with 25% children scoring above 90% marks every year.
  No fees is charged from EWS children or if a family falls in Casual financial crisis due to
sickness or any other reasons. The Trust Supports them in such need so that education
of the child is not affected.
                    </div>
                </motion.div>

                <h3 className='mt-4 underline'>Future Projection</h3>
                <motion.div id="vision" className='md:flex justify-center font-semibold my-10' 
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div id="content" className='mx-2 md:w-[50%]'>
                        Creative high School has a very unique reputation in the city of its location. We are
                        trying to buy and build suitable accommodation in Premises of its own with sufficient
                        place to accommodate about 5000 children in future.
                        At present 50% of the applicants every year are not granted admission due to paucity of
                        space and facilities <br/>
                        And this we are trying to achieve with the help of our well wishers, CSR Funds and etc.
                        We are confident to accomplish this task of having school's own place, infrastructure
                        and required amenities at and earlier date.
                    </div>
                </motion.div>
            </div>

            {/* <div id="imggrid"> */}
                <div className="overflow-x-hidden grid grid-cols-2 md:grid-cols-3 gap-4" >
                    <div>
                        <img className="h-auto max-w-full rounded-lg" src={pg21} alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full rounded-lg" src={pg22} alt=""/>
                    </div>
                    <div>
                        <img className="h-auto max-w-full rounded-lg" src={pg23} alt=""/>
                    </div>
                    <div className='md:hidden'>
                        <img className="h-auto max-w-full rounded-lg" src={pg24} alt=""/>
                    </div>
                    <div className='md:hidden'>
                        <img className="h-auto max-w-full rounded-lg" src={pg25} alt=""/>
                    </div>
                </div>
                <div className='m-4 hidden md:block'>
                    <div className='flex justify-center '>
                        <div className=' '>
                            <img className="h-auto max-w-full rounded-lg" src={pg24} alt=""/>
                        </div>
                        <div className=''>
                            <img className="h-auto max-w-full rounded-lg" src={pg25} alt=""/>
                        </div>
                    </div>
                </div>

            {/* </div> */}

        </div>
    </>
  )
}

export default Ourngo