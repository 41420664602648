import React, { useState } from 'react'

import logo from "../Assets/logo.png"
// icons
import { IoMail, IoClose } from "react-icons/io5";
import { CiInstagram, CiFacebook, CiTwitter, CiLinkedin, CiMenuBurger } from "react-icons/ci";
import { easeInOut, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
    const [openmenu, setOpenMenu] = useState(false)

    const navigate = useNavigate()

  return (
    <>
        <motion.div className='p-3 flex items-center justify-between shadow-md z-30'>
            <motion.div id="logo"
                whileHover={{
                    scale: 1.3,
                    paddingTop:"2vh",
                    x: "40px"
                }}
            >
                <img className='w-auto h-[3rem] md:h-[4rem]' src={logo} alt="" />
            </motion.div>

            <div id="menuicon" className='mt-2 md:hidden '>
                {
                    openmenu ? (
                            <IoClose size={23} onClick={() => setOpenMenu(!openmenu)}/>
                        ) : (
                            <CiMenuBurger size={23} onClick={() => setOpenMenu(!openmenu)}/>
                        )
                }
            </div>

            <div id="pages" className='hidden md:block justify-end'>
                <div className='flex gap-3'>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/')}}>OUR HISTORY</div>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/ourngo')}}>OUR NGO</div>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/aboutus')}}>ABOUT US</div>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/meritboard')}}>MERIT BOARD</div>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/commitment')}}>OUR COMMITMENTS</div>
                    <div className='p-1 px-2 font-bold bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition ease-in-out duration-300' onClick={() => {navigate('/contactus')}}>CONTACT US & LOCATION</div>
                </div>
            </div>
        </motion.div>
        {
            openmenu && (
                <motion.div id="menu" className='m-2 p-2 rounded-lg bg-gray-100'
                initial={{
                    y: '-100%', opacity: 0
                }}
                animate={{
                    y: 0, opacity: 1, transition: easeInOut
                }}

                >
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/'); setOpenMenu(!openmenu)}}>Our History</div>
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/ourngo'); setOpenMenu(!openmenu)}}>Our NGO</div>
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/aboutus'); setOpenMenu(!openmenu)}}>About Us</div>
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/meritboard'); setOpenMenu(!openmenu)}}>Merit Board</div>
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/commitment'); setOpenMenu(!openmenu)}}>Our Commitments</div>
                    <div className='p-1 bg-white my-1 rounded-md hover:bg-[#0f3661] hover:text-white transition animate-pulse ease-in-out duration-300' onClick={() => {navigate('/contactus'); setOpenMenu(!openmenu)}}>Contact Us & Location</div>
                </motion.div>
            )
        }
    </>
  )
}

export default Navbar