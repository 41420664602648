import { motion } from 'framer-motion'
import React from 'react'

import deepakkumar from "../Assets/deepakkumar.png"

import pg31 from "../Assets/pg31.jpeg"
import pg32 from "../Assets/pg32.jpeg"
import pg33 from "../Assets/pg33.jpeg"
import pg34 from "../Assets/pg34.jpeg"
import pg35 from "../Assets/pg35.jpeg"
import pg36 from "../Assets/pg36.jpeg"
import pg37 from "../Assets/pg37.jpeg"
import pg38 from "../Assets/pg38.jpeg"
import pg39 from "../Assets/pg39.jpeg"

import anthem from "../Assets/anthem.jpg"

const Aboutus = () => {
  return (
    <>
        <div id="container" className='overflow-x-hidden font-bold'>
            <motion.h2 className='text-center my-4'
                initial={{
                    x: 100
                }}
                
                whileInView={{
                    x: 0,
                    transition: "ease-in-out",
                    transitionDuration: 2,
                    transitionDelay: 1
                }}
            >
                AboutUs
            </motion.h2>
            <motion.div id="fromfoundersdesk" className='grid place-items-center'
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1,
                    transition: { delay: .2 },
                    transitionDuration: 2
                }}
            >
                <h4>FROM FOUNDER'S DESK</h4>
                <p className='text-center mx-2 md:w-[60%]'>
                    Our Journey began 28 years ago with few Nursery Children accommodated in a garage
                    by furnishing it and now they are 650 of them. As we continue to work on our
                    commitment to enable children from humble and under privileged background. We will
                    continue to strive hard to empower the children with quality education, educational
                    guidance and academic career adoption. As an organization we have man power and
                    confidence but if we have a proper infrastructure we can work for at least three times
                    more children and we may reduce the fees structure too. I appeal for support to our vision and mission with faith and goodwill.
                </p>
            </motion.div>

            <motion.div className='my-14 grid place-items-center'>
                <h4>MESSAGE FROM PRINCIPAL.</h4>
                <p className='text-left mx-2 md:w-[60%]'>
                    <span className='font-bold'>
                    Dear Parents and Students,    
                    </span> <br/>
                    It is with great pleasure that I welcome you to our school website. <br/> <br/>
                    As a Principal I am hugely impressed by the commitment of Management and staff for
                    providing excellent all round education. As a team working together, we strongly
                    promote academic and cultural achievements among our students. <br/> <br/>
                    We wish for bright and brilliant future of students. I know that every student wishes to
                    become successful in life. Each student goal is different and each student has different
                    path for success. The road to success and accomplishment of set goals is not always
                    direct or easy one. If you desire for great goal you should distance yourself from these
                    six vices-excess sleep, lethargy, fear, anger, laziness and procrastination.
                    <br/> <span className='font-bold'>
                    Best of Luck!
                    </span>
                </p>
                <div id="wishesandimage" className='md:flex md:w-[60%] items-center justify-between'>
                    <div id="wishes">
                    (That is the knowledge which is for liberation) <br/>
                    Deepak Kumar Singh M.A.(English)M.A.(Sanskrit) <br/>
                    B.Ed
                    </div>
                    <div id="image" className='text-center place-items-center'>
                        <img src={deepakkumar} className='h-[250px]' alt="Deepak Kumar Singh" />
                        ( Deepak Kumar Singh ) <br/>Principal
                    </div>
                </div>
            </motion.div>

            <div id="textcontent">
                <motion.h2 className='text-center my-4'
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    OUR AIMS AND AESTIMARE
                </motion.h2>
                <div id="textContent" className='grid place-items-center'>
                    <motion.p className='md:text-left mx-2 md:w-[60%]'
                        initial={{
                            x: -150
                        }}
                        
                        whileInView={{
                            x: 0,
                            transition: "ease-in-out",
                            transitionDuration: 2,
                            transitionDelay: 1
                        }}
                    >
                        <ul>
                            <li>
                                - To form as well as Reform. Empower them to achieve their greatest potential,
                            </li>
                            <li>
                                - To strengthen the will that it may have the power to practice virtue and reject wise
                            </li>
                            <li>
                                - To train the minds to analyze rather than memorize so that it may distinguish right from wrong
                            </li>
                            <li>
                                - To instill culture
                            </li>
                            <li>
                                - To stimulate ambition, Make them confident
                            </li>
                            <li>
                                - To disdain mediocrity and developed leadership develop inquisitive minds.
                            </li>
                            <li>
                                - To train future citizens for time and eternity to form the most effective human resource
                            </li>
                            <li>
                                - Fostering Academic excellence
                            </li>
                            <li>
                                - To be always friendly, polite, courteous and helping to needy
                            </li>
                            <li>
                                - To avoid rudeness and vulgarity in talks, behavior in and out of the school
                            </li>
                            <li>
                                - To accept the assigned work as rightful share
                            </li>   
                            <li>
                                - To prepare them for changing international scenario in context to India.
                            </li>
                            <li>
                                - Guiding for making passionate career.
                            </li>
                        </ul>
                    </motion.p>
                </div>

                <div id="titbits" className='grid place-items-center'>
                    <motion.h2 className='text-center my-4'
                        initial={{
                            x: 100
                        }}
                        
                        whileInView={{
                            x: 0,
                            transition: "ease-in-out",
                            transitionDuration: 2,
                            transitionDelay: 1
                        }}
                    >
                        SOME MORE TITBITS AND TIDINGS
                    </motion.h2>
                    <p className='mx-2 md:w-[60%]'>
                        <motion.div className='md:flex gap-4 my-3 items-center'
                            initial={{
                                x: 100
                            }}
                            whileInView={{
                                x: 0,
                                animation: "ease-in-out",
                                animate: "visible",
                                transitionDuration: 1
                            }}
                        >
                            <div>
                                > General knowledge and quiz classes and contest <br/>
                                > Ethics, religion, and mythology class to give them a scope to understand philosophy of all the religions <br/>
                            </div>
                            <div id="img" className='md:flex'>
                                <img src={pg31} className='h-[150px] w-full' alt="" />
                                <img src={pg32} className='h-[150px] w-full' alt="" />
                            </div>
                        </motion.div>

                        <motion.div className='md:flex grid place-items-center gap-4 my-3 items-center'
                            initial={{
                                x: -80
                            }}
                            whileInView={{
                                x: 0,
                                animation: "ease-in-out",
                                animate: "visible",
                                transitionDuration: 1
                            }}
                        >
                            <div id="img" className='flex'>
                                {/* <img src={pg33} className='h-[150px] w-full' alt="" /> */}
                                <img src={pg34} className='h-[70vh] w-auto' alt="" />
                            </div>
                            <div>
                                > Stress on individual speaking and presentations <br/>
                                > Children are made aware of social problems and encouraged to involve i community service <br/>
                            </div>
                        </motion.div>

                        <motion.div className='md:flex gap-4 my-3 items-center'
                            initial={{
                                x: 100
                            }}
                            whileInView={{
                                x: 0,
                                animation: "ease-in-out",
                                animate: "visible",
                                transitionDuration: 1
                            }}
                        >
                            <div>
                                > Besides picnic other educational trips are organised <br/>
                                > Project work by students of senior KG and onwards. Skill based activities. <br/>
                            </div>
                            <div id="img" className='flex'>
                                {/* <img src={pg35} className='h-[150px] w-full' alt="" /> */}
                                <img src={pg36} className='h-[70vh] w-auto' alt="" />
                            </div>
                        </motion.div>

                        <motion.div className=' gap-4 my-3 items-center'
                            initial={{
                                x: -100
                            }}
                            whileInView={{
                                x: 0,
                                animation: "ease-in-out",
                                animate: "visible",
                                transitionDuration: 1
                            }}
                        >
                            <div id="img" className='md:flex'>
                                <img src={pg37} className='h-[150px] w-full' alt="" />
                                <img src={pg38} className='h-[150px] w-full' alt="" />
                            </div>
                            <div>
                                > Regular meditation and yoga practices to lead peaceful life. <br/>
                                > All the festivals starting from Guru Purnima, Govinda, Ganpati, Navratri, Eid, Garba, Diwali, Christmas and Holi are celebrated in the school <br/>
                                > Remedial teaching done free for any child to bring up to the required level <br/>
                            </div>
                        </motion.div>
                    </p>
                </div>

                <div id="schoolanthem" className='p-14'
                    className="grid place-items-center"
                    // style={{backgroundImage: `url(${anthem})`, backgroundRepeat: "no-repeat"}}
                >
                    <motion.h2 className='text-center my-14'
                        initial={{
                            x: 100
                        }}
                        
                        whileInView={{
                            x: 0,
                            transition: "ease-in-out",
                            transitionDuration: 2,
                            transitionDelay: 1
                        }}
                    >
                        SCHOOL ANTHEM
                    </motion.h2>
                    <p className='text-center '>
                    <span className='font-extrabold'>HAIL CREATIVES.</span> {""}<br/> <br/>
                    V R Creatives, VR Creatives, V R Creatives... (3) <br/>
                                              V have a dream..(2) <br/>
                                               V have a mind ...(2) <br/>
                          V shall reach the sky, V need no wings..(2) <br/>
                                  Becoz V R the God's chosen ones. <br/>
                             Becoz V R Creatives, V R Creatives. (3) <br/> (2) <br/>
                                          V have determination..(2) <br/>
                                           V have perseverance...(2) <br/>
                          V have countless falls but power to rise...(2) <br/>
                                    Becoz V R the God's chosen ones <br/>
                              Becoz V R Creatives, V R Creatives...(3) <br/> (3) <br/> 
                                                VR Tenacious....2 <br/>
                                                 VR Resilient ...2 <br/>
                              V Have a nerve to dare the challenges <br/>
                                    Becoz V R the God's chosen ones <br/>
                              Becoz V R Creatives, V R Creatives...(3) <br/>
                    </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default Aboutus