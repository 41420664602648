import React from "react";
import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Ourhistory from "./Pages/Ourhistory";
import { CustomProvider, Container } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Footer from "./Components/Footer";
import Ourngo from "./Pages/Ourngo";
import Aboutus from "./Pages/Aboutus";
import Meritboard from "./Pages/Meritboard";
import Commitments from "./Pages/Commitments";
import Contactlocation from "./Pages/Contactlocation";

function App() {
  return (
    <>
    <CustomProvider theme="light">
      <Container className="app">
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Ourhistory />}/>
            <Route path="/ourngo" element={<Ourngo />}/>
            <Route path="/aboutus" element={<Aboutus />}/>
            <Route path="/meritboard" element={<Meritboard />}/>
            <Route path="/commitment" element={<Commitments />}/>
            <Route path="/contactus" element={<Contactlocation />}/>
          </Routes>
          <Footer />
        </Router>
      </Container>
    </CustomProvider>
    </>
  );
}

export default App;
