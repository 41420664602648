import { motion } from 'framer-motion';
import React from 'react'
import { Carousel, Caption, RadioGroup, Radio, Divider } from 'rsuite';

import rpdevani from "../Assets/mr_rp_devani.jpg"
import sudeshhooda from "../Assets/mrs_sudesh_hooda.jpg"
import bg2 from "../Assets/bg2.svg"
import home_img1 from "../Assets/home_img1.jpg"
import home_img2 from "../Assets/home_img2.jpg"
import priyankachopra from "../Assets/priyankachopra.jpg"
import vidhyachabria from "../Assets/vidhyachabria.jpg"
import vikrantbhargava from "../Assets/vikrantbhargava.jpg"
import founder from "../Assets/founder.jpeg"

import h1 from "../Assets/h1.jpg"
import h2 from "../Assets/h2.jpg"
import h3 from "../Assets/h3.jpg"
import h4 from "../Assets/h4.jpg"
import h5 from "../Assets/h5.jpg"


const Ourhistory = () => {

  return (
    <>
        <div id="container" className='overflow-x-hidden'>

        <Carousel
            autoplay
            interval={2000}
            className="custom-slider h-auto"
            style={{ maxWidth: "100%", height: "auto", overflow: "hidden", backgroundColor: "white" }} // Set height to auto
        >
            <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}> {/* Container for the first image */}
                <img src={h4} style={{ maxWidth: "100%", height: "auto", width: "100%" }} alt="Image 1" /> {/* Ensure the image fits within the container */}
            </div>
            <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}> {/* Container for the second image */}
                <img src={h1} style={{ maxWidth: "100%", height: "auto", width: "100%" }} alt="Image 2" />
                <p className='absolute font-extrabold text-white md:text-6xl text-center' style={{ top: 100, left: 150}}>Every child deserves the best</p>
            </div>
            <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}> {/* Container for the third image */}
                <img src={h2} style={{ maxWidth: "100%", height: "auto", width: "100%" }} alt="Image 3" />
                <p className='absolute font-extrabold text-white md:text-6xl text-center' style={{ top: 100, left: 120}}>Quality education means not only what to learn but also how to learn</p>
            </div>
            <div style={{ position: "relative", maxWidth: "100%", height: "auto" }}> {/* Container for the fourth image */}
                <img src={h3} style={{ maxWidth: "100%", height: "auto", width: "100%" }} alt="Image 4" />
                <p className='absolute font-extrabold text-white md:text-6xl text-center ' style={{ top: 80, left: 130}}>Journey of a thousand miles<br/>begins with single step - Lao Tzu</p>
            </div>
            {/* <img src={h5} height="250" /> */}
        </Carousel>



            <motion.div className='p-4'
                initial={{
                    opacity: 0, x: -100
                }}
                animate={{
                    opacity: 1, x: 1
                }}
                transition={{ duration: 1, delay: .2 }}
            >
                <div className='text-xl  text-center font-bold'>
                    <h3 className=''> Welcome at our site of <span className='font-bold text-[#0F3661]'>CREATIVE HIGH SCHOOL</span> </h3>
                    <br/> <span className='underline text-green-700'>Creative High School</span> at Panchpakhadi was conceived and Established by a group of educationists and teachers of Thane.         
                </div>
            </motion.div>

            <motion.div id="image-container" style={{backgroundImage: `url(${bg2})`, backgroundSize: "cover", backgroundRepeat: "no-repeat"}} class="flower-bg max-w-screen-2xl mx-auto"
                initial={{
                    scale: 0,
                }}
                
                whileInView={{
                    scale: 1,
                    transition: "ease-in-out",
                    transitionDuration: 2,
                    transitionDelay: 0.2
                }}
            >
                <div class="grid grid-cols-2 px-3 py-2 text-center">
                    <h1 class="font-bold text-sm">
                    OUR INSPIRATION    
                    </h1>
                    <h1 class="font-bold text-sm">
                    SCHOLARY  ACADEMICIAN 
                    </h1>
                </div>
                <div id="images-container"
                    class="grid grid-cols-2 gap-2 place-items-center
                            px-2 py-3
                    "
                >
                    <div id="img1" class="h-[15rem] w-auto">
                        <img class="rounded h-full w-full" src={sudeshhooda} alt="Late Mrs. Sudesh Hooda Educationist" />
                    </div>
                    <div id="img2" class="h-[15rem] w-auto">
                        <img class="rounded h-full w-full" src={rpdevani} alt="Late.Mr. R.P.Devani Disciplinarian, Educator,Conuselor and intellecture" />
                    </div>
                </div>
                <div class="grid grid-cols-2 px-3 py-2 text-center">
                    <h1 class="font-bold text-sm">
                    Late Mrs. Sudesh Hooda
                    
                    </h1>
                    <h1 class="font-bold text-sm">
                    Late Mr. R.P. Devani
                    </h1>
                </div>
            </motion.div>
        </div>
        <div id="photos" className='md:flex'>
            <div className='md:w-[50vw] overflow-hidden'>
                <img className='h-full w-full' src={home_img1} alt="" />
            </div>
            <div className='md:w-[50vw] overflow-hidden'>
                <img className='h-full w-full' src={home_img2} alt="" />
            </div>
        </div>
        <div className='text-md md:text-xl font-semibold text-center my-2'>
            TEACHER'S STATURE HAS ONLY ONE MEASURE - THAT IS STUDENT'S ATTITUDE   
        </div>

        <div id="wellwishes" className='text-center my-6 font-bold'>
            <motion.p className='text-2xl'
                initial={{
                    y: '-50%', opacity: 0, scale: .9
                }}
                
                whileInView={{
                    y: '0%',
                    opacity: 1,
                    scale: 1,
                    transition: {
                        staggerChildren: .3,
                        delayChildren: .2,
                    },
                    transitionDuration: 2,
                    transitionDelay: 2
                }}
            >
                OUR WELL WISHERS AND INITIAL SUPPORTERS.
            </motion.p>
            <motion.div id="well-wishers" className='grid place-items-center gap-6 md:flex md:justify-center'>
                <motion.div id="priyanka-chopra"
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div className='h-[15rem] w-[15rem]'>
                        <img src={priyankachopra} className='h-[15rem] w-[15rem]' alt="Priyanka Chopra" />
                    </div>
                    <p>Priyanka Chopra</p>
                    <p>( Film Star )</p>
                </motion.div>

                <motion.div id="vikrantbhargava"
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div className='h-[15rem] w-auto'>
                        <img src={vikrantbhargava} className='h-[15rem] w-[15rem]' alt="Vikrant Bhargava" />
                    </div>
                    <p>Mr. Vikrant Bhargava</p>
                    <p>( Businessman UK )</p>
                </motion.div>

                <motion.div id="vidhyachabria"
                    initial={{
                        x: -100
                    }}
                    
                    whileInView={{
                        x: 0,
                        transition: "ease-in-out",
                        transitionDuration: 2,
                        transitionDelay: 1
                    }}
                >
                    <div className='h-[15rem] w-auto'>
                        <img src={vidhyachabria} className='h-[15rem] w-[15rem]' alt="Vidhya Chabria" />
                    </div>
                    <p>Mrs. Vidhya M. Chabria</p>
                    <p>( Chairperson Jumbo Group Dubai )</p>
                </motion.div>
            </motion.div>
        </div>
                <h4 className='my-5 text-center ' >The Founder</h4>
        <div id="founder" className='md:flex justify-items-center gap-6 mx-3 md:mx-14'>
            <div className='grid place-items-center'>
                <div className='border  p-2 border-black grid place-items-center'>
                    <img src={founder} alt="" className='h-[15rem]'/>
                    <p className='font-semibold text-lg'>Mrs. Kamla Omprakash Kanaujia</p>
                    <p className='text-xs text-center font-bold'>A TEACHER PAR EXCELLENCE, EX-PRINCIPAL & PRESENT SCHOOL ADMINISTRATOR CREATIVE HIGH SCHOOL (EST. 1998)</p>
                </div>
            </div>
            <div id="text" className='my-2 text-xl font-bold'>
                <p className=''>
                    It was in the years of nineties when Govt. of India had adopted free Trade policies in the country. Thane - a heavily industrialized city near Mumbai in Maharashtra, was growing at a phenomenal pace. Multinational Companies were moving in and establishing industrial units. This was the impact of newly adopted free Trade policy of the Government.
                </p>
                <p className=''>
                    Because of international set-ups at Thane to educate children in English Medium School became a most sought after trend among parents. But there were very few English medium schools at Thane that time. Especially children from humble background and EWS families were left out.
                </p>
                <p className=''>
                    In such circumstances to empower the kids with humble background to get admission and education in English Medium - Creative High School was founded by group of Teachers and Academicians of Thane City by Furnishing a Garage.
                </p>
                <p className=''>
                    Today creative School has nursery, Pre-Primary, Primary and secondary school affiliated to Mumbai SSC Divisional Board. The school has a strength of 650 Children. At the same time it is not able to grant admission to more than 50% of applicants every year due to paucity of space and facilities.
                </p>
                <p className=''>
                    The feelings go beyond words when a widow housemaid's daughter goes to study engineering in premier institution like VJTI in Mumbai. This is just one example for what creative school is devoted and established for. There are many more such instances of the families of Rikshaw Driver, Vegetable and food vendors, Old paper vendor's children achieved exceptionally with our special efforts on their academics and have adopted different profession in Technical, IT and hospitality, Accountancy etc.etc.
                </p>
            </div>
        </div>
    </>
  )
}

export default Ourhistory