import { motion } from 'framer-motion'
import React from 'react'

import home_img2 from "../Assets/home_img2.jpg"

import pg41 from "../Assets/pg41.jpeg"
import pg42 from "../Assets/pg42.jpeg"
import pg43 from "../Assets/pg43.jpeg"
import pg44 from "../Assets/pg44.jpeg"
import pg45 from "../Assets/pg45.jpeg"
import pg46 from "../Assets/pg46.jpeg"

const Meritboard = () => {
  const data = [
    {
      no: "01",
      year: "2010",
      name: "NIRANJAN RAVINDRA GORE",
      percent: "92.00%"
    },
    {
      no: "02",
      year: "2011",
      name: "JAYSHREE GOVIND MANJREKAR",
      percent: "88.00%"
    },
    {
      no: "03",
      year: "2012",
      name: "SIDDHI RAMESH SHIRSAT",
      percent: "92.55%"
    },
    {
      no: "04",
      year: "2013",
      name: "SHALVI VILAS DALVI",
      percent: "95.27%"
    },
    {
      no: "05",
      year: "2014",
      name: "ANUSHA JAGANNATH POOJARY",
      percent: "91.80%"
    },
    {
      no: "06",
      year: "2015",
      name: "NIKHIL ARUN MANE",
      percent: "83.20%"
    },
    {
      no: "07",
      year: "2016",
      name: "KHUSHI MAHENDRA JAIN",
      percent: "92.80%"
    },
    {
      no: "08",
      year: "2017",
      name: "RASHMI SURESH GIRI",
      percent: "96.20%"
    },
    {
      no: "09",
      year: "2018",
      name: "HARSHAL SANJAY JADHAV",
      percent: "94.40%"
    },
    {
      no: "10",
      year: "2019",
      name: "ABHAY SURESH GIRI",
      percent: "93.60%"
    },
    {
      no: "11",
      year: "2020",
      name: "KASHISH VIMAL SHAH",
      percent: "96.00%"
    },
    {
      no: "12",
      year: "2021",
      name: "YASH VIVEK CHIPKAR",
      percent: "96.60%"
    },
    {
      no: "13",
      year: "2022",
      name: "SHRUTI SANJAY WALAM",
      percent: "95.40%"
    },
    {
      no: "14",
      year: "2023",
      name: "RIYA RAVINDRA ZENDE ",
      percent: "93.80%"
    },
  ]


  return (
    <>
      <motion.div id="container" className='grid place-items-center font-bold'>
        <h4 className='text-center'>
          MERIT BOARD WITH OUT TOPPER'S IN SSC SINCE INCEPTION
        </h4>
        <table className='my-5'>
          <tbody>
            <tr className="header border">
              <th className='border border-black px-3'>NO.</th>
              <th className='border border-black px-3'>YEAR</th>
              <th className='border border-black px-3'>NAME</th>
              <th className='border border-black px-3'>PERCENTAGE</th>
            </tr>

            {
              data.map((item, index) => (
                <tr key={index}>
                  <td className='border border-black px-3'>{item.no}</td>
                  <td className='border border-black px-3'>{item.year}</td>
                  <td className='border border-black px-3'>{item.name}</td>                                    
                  <td className='border border-black px-3'>{item.percent}</td>                                    
                </tr>
              ))
            }

           
          </tbody>
        </table>
        <div className="grid place-items-center">
          <img src={home_img2} className='h-[400px]' alt="" />
        </div>
      </motion.div>

        <div class="my-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg41} alt="Image 1" class="w-full h-48 object-cover" />
              </div>

              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg42} alt="Image 2" class="w-full h-48 object-cover" />
              </div>

              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg43} alt="Image 3" class="w-full h-48 object-cover" />
              </div>

              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg44} alt="Image 4" class="w-full h-48 object-cover" />
              </div>

              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg45} alt="Image 5" class="w-full h-48 object-cover" />
              </div>

              <div class="bg-white rounded-md overflow-hidden shadow-md">
                  <img src={pg46} alt="Image 6" class="w-full h-48 object-cover" />
              </div>
        </div>

    </>
  )
}

export default Meritboard