import { motion } from 'framer-motion'
import React from 'react'

import pg51 from "../Assets/pg51.jpeg"
import pg52 from "../Assets/pg52.jpeg"
import pg53 from "../Assets/pg53.jpeg"
import pg54 from "../Assets/pg54.jpeg"
import pg55 from "../Assets/pg55.jpeg"
import pg56 from "../Assets/pg56.jpeg"
import pg57 from "../Assets/pg57.jpeg"
import pg58 from "../Assets/pg58.jpeg"
import pg59 from "../Assets/pg59.jpeg"
import pg510 from "../Assets/pg510.jpeg"
import pg511 from "../Assets/pg511.jpeg"



const Commitments = () => {
  return (
    <>
        <div id="container" className='grid place-items-center font-bold'>
            <motion.div
                className='text-center my-5'
                initial={{
                    opacity: 0, y: -150
                }}
                animate={{
                    opacity: 1, y: 0, transition:{delay:0.2}
                }}
            >
                <h3>
                    OUR COMMITMENTS
                </h3>
            </motion.div>
            <p className='md:w-[50%] text-center my-2'>
            To develop individual childs unique potential to the fullest unlimited programs
       are organized throughout the year to develop confidence with sense of competition
       and co-operation both. For this school children have been divided into three Houses.
            </p>

            <div id="nameofhouses" className='font-bold'>
            NAMES OF THE THREE HOUSES <br/>

1. Determination House <br/>
2. Dedication House <br/>
3. Devotion House <br/>
            </div>

            <p className='md:w-[50%] text-center my-2'>
            Each House has its own Motto, logo and leaders. Various competitions are organized
     among the Houses. They co-operate in joint projects and social service activities too on
     varied occasions in and out of school.
            </p>

            <div className='grid place-items-center my-6'>
                <span className='font-bold'>
                    STUDENT'S SCHOOL PARLIAMENT
                </span>
                <p className='md:w-[50%] text-center my-2'>
                It is a democratically functioning, elected School body of students,training them and
    fostering the patriotic mindset to develop the responsible, adult future citizens of
    India.

    School Parliament performs a lot many responsible duties to maintain discipline and
   civic sense in students among school age and later life.
            </p>
            </div>

            <h2>PRISMATIC KALEIDOSCOPE</h2>

            {/* gallery */}
            

            <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg51} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg52} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg53} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg54} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg55} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg56} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg57} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg58} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg59} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg510} alt=""/>
                </div>
                <div>
                    <img class="h-auto max-w-full rounded-lg" src={pg511} alt=""/>
                </div>
            </div>

        </div>
    </>
  )
}

export default Commitments