import React from 'react'

const Contactlocation = () => {
  return (
    <>
        <div id="container" className='p-5 text-xl'>
            <div id="head1">
                <h4>Our Contacts and Location</h4>
                <div className='grid place-items-center'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1254.1078427892808!2d72.96544551850879!3d19.19834358361517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b9170ccd97f7%3A0xbaad8dc2212be147!2sAlmeda%20Rd%2C%20Ramabai%20Ambedkar%20Nagar%2C%20Ganeshwadi%2C%20Thane%20West%2C%20Thane%2C%20Maharashtra%20400601!5e0!3m2!1sen!2sin!4v1706194019946!5m2!1sen!2sin" width="1250" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div id="infor" className='md:flex my-5'>
                <div>
                    <h6>EMAIL ID</h6>
                </div>
                <div className='px-4 font-bold text-md'>
                    creativeschool@rediffmail.com <br/>
                    refusetobesilenced@rediffmail.com
                </div>
            </div>
            <div>
                <h5>REGISTERED OFFICE ADDRESS :</h5>
                <p>7, SHIVRAJ NAGAR, ALMEIDA ROAD, PANCHPAKHADI, THANE W, PIN : 400602</p>
            </div>
            <div id="infor" className='md:flex my-5'>
                <div>
                    <h6>CONTACT NO.</h6>
                </div>
                <div className='px-4 font-bold flex-wrap text-md'>
                    9987726666 / 9967635555 / 9833297195 / 9867503246
                </div>
            </div>
            <div>
                <h6 className='text-center'>OUR BANKERS :</h6>

                <div id="1" className='grid place-items-start my-4'>
                    (1) <br/>

                    <p>
                        <span className='font-bold'>BENEFICIARY :</span> OPK EDUCATIONAL AND CHARITABLE TRUST <br/>

                        <span className='font-bold'>BANK A/C NO.</span> 60242732816 <br/>

                        <span className='font-bold'>BANKS NAME :</span> BANK OF MAHARATHRA, TMC BRANCH, THANE (W) 400602<br/>

                        <span className='font-bold'>IFSC CODE :</span> MAHB0001216 <br/>

                        <span className='font-bold'>
                            *THIS ACCOUNT IS FOR REMITTENCES FROM WITHIN INDIA ONLY.*
                        </span>
                    </p>
                </div>

                <div id="2" className='grid place-items-start my-4'>
                    (2) <br/>

                    <p>
                        <span className='font-bold'>BENEFICIARY :</span> CREATIVE HIGH SCHOOL, THANE (W) 400602<br/>

                        <span className='font-bold'>BANK A/C NO.</span> 20010719434 <br/>

                        <span className='font-bold'>BANKS NAME :</span> BANK OF MAHARASHTRA, TMC BRANCH, THANE W <br/>

                        <span className='font-bold'>IFSC CODE :</span> MAHB0001216 <br/>

                        <span className='font-bold'>
                            *THIS ACCOUNT IS FOR REMITTENCES FROM WITHIN INDIA ONLY. *
                        </span>
                    </p>
                </div>

                <div id="3" className='grid place-items-start my-4'>
                    (3) <br/>

                    <p>
                        <span className='font-bold'>BENEFICIARY :</span> OPK EDUCATIONAL AND CHARITABLE TRUST <br/>

                        <span className='font-bold'>BANK A/C NO.</span> 003501035198 <br/>

                        <span className='font-bold'>BANKS NAME :</span> ICICI BANK, GLENMORGAN, VEER SAVARKAR MARG, THANE , MAHARASHTRA, INDIA 400602 <br/>

                        <span className='font-bold'>IFSC CODE :</span> ICIC0000035 <br/>

                        <span className='font-bold'>
                            *FCRA A/C FOR INTERNATIONAL REMITTENCES ONLY.*
                        </span>
                    </p>
                </div>
                <h5 className='text-center'>DONATE GENEROUSLY FOR THE CAUSE OF SOCIETY'S FUTURE PROSPECTS.</h5>
            </div>
        </div>
    </>
  )
}

export default Contactlocation